
import Vue from "vue";
import {mapState} from 'vuex'
import {ObjectUtils} from "~/assets/utils/ObjectUtils"

export default Vue.extend({
	name: 'Sidebar',
	computed: {
		...mapState(['idgroup', 'navigation', 'domain']),
	},
	data: () => ({
		loaded: false,
		isOpen: null,
		domaingroup: [],
		navmotor: [],
		navhome: [],
		groupMotor: [],
		motorPath: [],
		groupHome: [],
		homePath: []
	}),
	methods: {
		buildNav() {
			/**
			 * @author @Simo mailto:simoneolivieri@kubepartners.com
			 * @description //retrive nagivation array from store and groupby domain
			 * @date 09:53 18/04/23
			 * @param mapState(['navigation'])
			 * @return groupByKey
			 **/
			this.domaingroup = Object.keys(this.navigation)
			this.navmotor = ObjectUtils.groupByKey(this.navigation['motor'], 'idgroup')
			this.navhome = ObjectUtils.groupByKey(this.navigation['home'], 'idgroup')
			this.groupMotor = Object.keys(this.navmotor)
			this.groupHome = Object.keys(this.navhome)

			Object.entries(this.navmotor).forEach((v, i) => {
				let paths = v.slice(-1).pop()
				this.motorPath.push(paths[0].path)
			});

			Object.entries(this.navhome).forEach((v, i) => {
				let paths = v.slice(-1).pop()
				this.homePath.push(paths[0].path)
			});
		},
		noSubnav() {

		},
		openSubnav(event) {
			const boxes = document.querySelectorAll('.mainNav')
			boxes.forEach(box => {
				if (box !== event.currentTarget) {
					box.classList.remove('isOpen')
				}
			})
			event.currentTarget.classList.toggle('isOpen')
		}
	},
	mounted() {
		this.loaded = true
		this.buildNav()
		// console.log(this.navmotor)
	}
})
