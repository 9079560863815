export const LoginForm = () => import('../../assets/components/gui/auth/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c))
export const Logout = () => import('../../assets/components/gui/auth/Logout.vue' /* webpackChunkName: "components/logout" */).then(c => wrapFunctional(c.default || c))
export const User = () => import('../../assets/components/gui/auth/User.vue' /* webpackChunkName: "components/user" */).then(c => wrapFunctional(c.default || c))
export const LoginAnim = () => import('../../assets/components/gui/brand/LoginAnim.vue' /* webpackChunkName: "components/login-anim" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../assets/components/gui/brand/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const LogoLogin = () => import('../../assets/components/gui/brand/LogoLogin.vue' /* webpackChunkName: "components/logo-login" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../assets/components/gui/button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Datepicker = () => import('../../assets/components/gui/datepicker/Datepicker.vue' /* webpackChunkName: "components/datepicker" */).then(c => wrapFunctional(c.default || c))
export const FiltersHome = () => import('../../assets/components/gui/filters/FiltersHome.vue' /* webpackChunkName: "components/filters-home" */).then(c => wrapFunctional(c.default || c))
export const FiltersMotor = () => import('../../assets/components/gui/filters/FiltersMotor.vue' /* webpackChunkName: "components/filters-motor" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = () => import('../../assets/components/gui/input/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const InputMask = () => import('../../assets/components/gui/input/InputMask.vue' /* webpackChunkName: "components/input-mask" */).then(c => wrapFunctional(c.default || c))
export const InputNumber = () => import('../../assets/components/gui/input/InputNumber.vue' /* webpackChunkName: "components/input-number" */).then(c => wrapFunctional(c.default || c))
export const InputSwitch = () => import('../../assets/components/gui/input/InputSwitch.vue' /* webpackChunkName: "components/input-switch" */).then(c => wrapFunctional(c.default || c))
export const InputText = () => import('../../assets/components/gui/input/InputText.vue' /* webpackChunkName: "components/input-text" */).then(c => wrapFunctional(c.default || c))
export const Knob = () => import('../../assets/components/gui/input/Knob.vue' /* webpackChunkName: "components/knob" */).then(c => wrapFunctional(c.default || c))
export const RadioButton = () => import('../../assets/components/gui/input/RadioButton.vue' /* webpackChunkName: "components/radio-button" */).then(c => wrapFunctional(c.default || c))
export const Actions = () => import('../../assets/components/gui/navigation/Actions.vue' /* webpackChunkName: "components/actions" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../assets/components/gui/navigation/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../assets/components/gui/navigation/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const Theme = () => import('../../assets/components/gui/navigation/Theme.vue' /* webpackChunkName: "components/theme" */).then(c => wrapFunctional(c.default || c))
export const Range = () => import('../../assets/components/gui/rangeslider/Range.vue' /* webpackChunkName: "components/range" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../assets/components/gui/rangeslider/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const CascadeSelect = () => import('../../assets/components/gui/select/CascadeSelect.vue' /* webpackChunkName: "components/cascade-select" */).then(c => wrapFunctional(c.default || c))
export const CascadeSelectSub = () => import('../../assets/components/gui/select/CascadeSelectSub.vue' /* webpackChunkName: "components/cascade-select-sub" */).then(c => wrapFunctional(c.default || c))
export const Dropdown = () => import('../../assets/components/gui/select/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c))
export const Multiselect = () => import('../../assets/components/gui/select/Multiselect.vue' /* webpackChunkName: "components/multiselect" */).then(c => wrapFunctional(c.default || c))
export const SortLink = () => import('../../assets/components/gui/tables/SortLink.vue' /* webpackChunkName: "components/sort-link" */).then(c => wrapFunctional(c.default || c))
export const SortedTable = () => import('../../assets/components/gui/tables/SortedTable.vue' /* webpackChunkName: "components/sorted-table" */).then(c => wrapFunctional(c.default || c))
export const BarChart = () => import('../../assets/components/charts/BarChart.vue' /* webpackChunkName: "components/bar-chart" */).then(c => wrapFunctional(c.default || c))
export const ColumnChart = () => import('../../assets/components/charts/ColumnChart.vue' /* webpackChunkName: "components/column-chart" */).then(c => wrapFunctional(c.default || c))
export const HeatmapChart = () => import('../../assets/components/charts/HeatmapChart.vue' /* webpackChunkName: "components/heatmap-chart" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../assets/components/charts/LineChart.vue' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const MapChart = () => import('../../assets/components/charts/MapChart.vue' /* webpackChunkName: "components/map-chart" */).then(c => wrapFunctional(c.default || c))
export const MultiScatterChart = () => import('../../assets/components/charts/MultiScatterChart.vue' /* webpackChunkName: "components/multi-scatter-chart" */).then(c => wrapFunctional(c.default || c))
export const RadarChart = () => import('../../assets/components/charts/RadarChart.vue' /* webpackChunkName: "components/radar-chart" */).then(c => wrapFunctional(c.default || c))
export const RatingChart = () => import('../../assets/components/charts/RatingChart.vue' /* webpackChunkName: "components/rating-chart" */).then(c => wrapFunctional(c.default || c))
export const ScatterChart = () => import('../../assets/components/charts/ScatterChart.vue' /* webpackChunkName: "components/scatter-chart" */).then(c => wrapFunctional(c.default || c))
export const StackedBarChart = () => import('../../assets/components/charts/StackedBarChart.vue' /* webpackChunkName: "components/stacked-bar-chart" */).then(c => wrapFunctional(c.default || c))
export const TimelineChart = () => import('../../assets/components/charts/TimelineChart.vue' /* webpackChunkName: "components/timeline-chart" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
