
import Vue from "vue";
import {mapState} from 'vuex'

export default Vue.extend({
	name: 'grid',
	data: () => ({
		loading: true
	}),
	computed: {
		...mapState(['isfilters', 'isdatatable', 'navigation']),
	},
	methods: {},
	beforeCreate() {
		// @ts-ignore
		this.loading = true;
	},
	mounted() {
		this.loading = false;
	}
});
