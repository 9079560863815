import axios from 'axios'

export default function (context) {
	axios.interceptors.response.use(res => {
		return res
	}, error => {
		if (error.response.status === 401) {
			console.log(error)
			context.$auth.logout()
		}
		return error
	})
}
