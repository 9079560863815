
import Vue from "vue";
import {mapState} from 'vuex'

export default Vue.extend({
	name: 'grid',
	data: () => ({
		loading: true
	}),
	computed: {
		...mapState(['isfilters', 'isdatatable', 'navigation']),
	},
	methods: {
		noFilters() {
			let route = this.$route.path
			return [
				route === '/motor/motor_dash'||
				route === '/motor/comp_cls'||
				route === '/motor/left_table'||
				route === '/motor/near_miss' ||
				route === '/motor/prod_time' ||
				route === '/home/comp_cls'||
				route === '/home/left_table'||
				route === '/home/near_miss' ||
				route === '/home/prod_time'
						? 'noFilters' : 'withFilters'
			]
		},
		isBordered() {
			let route = this.$route.path
			let nav_motor = this.navigation['motor'][0]['path']
			let nav_home = this.navigation['home'][0]['path']
			// console.log(route, nav_home)
			return [
				route == nav_motor ||
				route == nav_home
						? '' : 'bordered'
			]
		}
	},
	beforeCreate() {
		// @ts-ignore
		this.loading = true;
	},
	mounted() {
		this.loading = false;
	}
});
