
import Vue from "vue";
import {getData} from "~/assets/api/kteApi";
import {mapGetters} from "vuex";

export default Vue.extend({
	name: 'user',
	computed: {
		...mapGetters(['isAuthenticated', 'loggedInUser']),
	},
	data() {
		return {
			userdata: [],
		}
	},
	methods: {
		async fetchUser() {
			await getData(process.env.BASE_URL + '/api/user', undefined, this.userdata, this.$auth.$state.token_local).then(arr => {
				this.userdata = JSON.parse(JSON.stringify(this.userdata[0]))
				// console.log(this.userdata)
				this.$auth.setUser(this.userdata.username)
				this.$store.commit('SET_ROLE', this.userdata.ruolo)
			})
		}
	},
	mounted() {
		this.fetchUser()
	}
})
