
import Vue from "vue";
import {mapState} from 'vuex'

export default Vue.extend({
	name: 'Actions',
	computed: {
		...mapState(['isfilters', 'isdatatable'])
	},
	methods: {
		hideTable() {
			let route = this.$route.path
			return [
				route === '/motor/motor_dash' ||
        route === '/home/home_dash'
							? 'dispNone' : ''
			]
		},
		noFilters() {
			let route = this.$route.path
			return [
        route === '/motor/motor_dash'||
				route === '/motor/comp_cls'||
				route === '/motor/left_table'||
				route === '/motor/near_miss' ||
				route === '/motor/prod_time'||
        route === '/home/home_dash'||
				route === '/home/comp_cls'||
				route === '/home/left_table'||
				route === '/home/near_miss' ||
				route === '/home/prod_time'
						? 'dispNone' : ''
			]
		},
		toggleFilters() {
			this.$store.commit('IS_DATATABLE', false)
			if (!this.isfilters) {
				this.$store.commit('IS_FILTERS', true)
			}
			else {
				this.$store.commit('IS_FILTERS', false)
			}
		},
		toggleDatatable() {
			this.$store.commit('IS_FILTERS', false)
			if (!this.isdatatable) {
				this.$store.commit('IS_DATATABLE', true)
			}
			else {
				this.$store.commit('IS_DATATABLE', false)
			}
		},
		emitDownload(){
			this.$nuxt.$emit('download_csv')
		}
	}
})
