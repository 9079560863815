
import Vue from "vue";

export default Vue.extend({
	name: 'default',
	data: () => ({
		loading: true
	}),
	beforeCreate() {
		// @ts-ignore
		this.loading = true;
	},
	mounted() {
		this.loading = false;
	}
});
