
import Vue from "vue";
import {mapState} from 'vuex'
import {ObjectUtils} from "~/assets/utils/ObjectUtils";

export default Vue.extend({
	name: 'Navbar',
	computed: {
		...mapState(['idgroup', 'navigation', 'nav_slot']),
	},
	data: () => ({
		motorgroup: [],
		homegroup: [],
		isSlot: false
	}),
	methods: {
		setSlot() {
			let route = this.$route.path
			this.isSlot = route === '/motor/motor_dash' || route === '/home/home_dash';
		}
	},
	mounted() {
		/**
		 * @author @Simo mailto:simoneolivieri@kubepartners.com
		 * @description //retrive nagivation array from store and groupby domain
		 * @date 09:55 18/04/23
		 * @param mapState(['idgroup','navigation'])
		 * @return groupByKey
		 **/
		this.motorgroup = ObjectUtils.groupByKey(this.navigation['motor'], 'idgroup')
		this.homegroup = ObjectUtils.groupByKey(this.navigation['home'], 'idgroup')
		this.setSlot()
		// console.log(this.motorgroup)
		// console.log(this.homegroup)
	}
})
