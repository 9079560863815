
import Vue from "vue";

export default Vue.extend({
	name: 'Theme',
	methods: {
		toggleSwitch() {
			this.color = this.$colorMode.preference = this.$colorMode.preference === 'light' ? 'dark' : 'light';
			this.$nuxt.$emit('colorMode');
		}
	},
	data: () => ({
		color: null,
	})

})
