
import Vue from "vue"

export default Vue.extend({
	name: 'Button',
	props: {
		label: {
			type: String
		},
		icon: {
			type: String
		},
		iconPos: {
			type: String,
			default: 'left'
		},
		badge: {
			type: String
		},
		badgeClass: {
			type: String,
			default: null
		}
	},
	computed: {
		buttonClass() {
			return {
				'k3-button k3-component': true,
				'k3-button-icon-only': this.icon && !this.label,
				'k3-button-vertical': (this.iconPos === 'top' || this.iconPos === 'bottom') && this.label,
				'k3-disabled': this.disabled
			}
		},
		iconClass() {
			return [
				this.icon,
				'k3-button-icon',
				{
					'k3-button-icon-left': this.iconPos === 'left' && this.label,
					'k3-button-icon-right': this.iconPos === 'right' && this.label,
					'k3-button-icon-top': this.iconPos === 'top' && this.label,
					'k3-button-icon-bottom': this.iconPos === 'bottom' && this.label
				}
			]
		},
		badgeStyleClass() {
			return [
				'k3-badge', this.badgeClass, {
					'k3-badge-no-gutter': this.badge && String(this.badge).length === 1
				}]
		}
	}
})
