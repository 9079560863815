import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c269bd36 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d34806b4 = () => interopDefault(import('../pages/home/comp_cls.vue' /* webpackChunkName: "pages/home/comp_cls" */))
const _0dfd0d2f = () => interopDefault(import('../pages/home/cost_credit.vue' /* webpackChunkName: "pages/home/cost_credit" */))
const _367a8f74 = () => interopDefault(import('../pages/home/down_files.vue' /* webpackChunkName: "pages/home/down_files" */))
const _11aba6c0 = () => interopDefault(import('../pages/home/first_prm.vue' /* webpackChunkName: "pages/home/first_prm" */))
const _f8cfc814 = () => interopDefault(import('../pages/home/home_dash.vue' /* webpackChunkName: "pages/home/home_dash" */))
const _7405f742 = () => interopDefault(import('../pages/home/left_table.vue' /* webpackChunkName: "pages/home/left_table" */))
const _dacd0368 = () => interopDefault(import('../pages/home/map_prm.vue' /* webpackChunkName: "pages/home/map_prm" */))
const _35e9f832 = () => interopDefault(import('../pages/home/market_price.vue' /* webpackChunkName: "pages/home/market_price" */))
const _31d060fb = () => interopDefault(import('../pages/home/mom_prm.vue' /* webpackChunkName: "pages/home/mom_prm" */))
const _ed18c992 = () => interopDefault(import('../pages/home/near_miss.vue' /* webpackChunkName: "pages/home/near_miss" */))
const _0743af62 = () => interopDefault(import('../pages/home/prm_comp.vue' /* webpackChunkName: "pages/home/prm_comp" */))
const _377e8ed2 = () => interopDefault(import('../pages/home/prm_prod.vue' /* webpackChunkName: "pages/home/prm_prod" */))
const _044f6728 = () => interopDefault(import('../pages/home/prod_compar.vue' /* webpackChunkName: "pages/home/prod_compar" */))
const _5f228239 = () => interopDefault(import('../pages/home/prod_time.vue' /* webpackChunkName: "pages/home/prod_time" */))
const _65178a5f = () => interopDefault(import('../pages/home/quot_ot.vue' /* webpackChunkName: "pages/home/quot_ot" */))
const _72ad5fa1 = () => interopDefault(import('../pages/home/quot_prm.vue' /* webpackChunkName: "pages/home/quot_prm" */))
const _66ab3abe = () => interopDefault(import('../pages/home/rnk_ot.vue' /* webpackChunkName: "pages/home/rnk_ot" */))
const _53f88d7c = () => interopDefault(import('../pages/home/what_if.vue' /* webpackChunkName: "pages/home/what_if" */))
const _ad876cf0 = () => interopDefault(import('../pages/motor/comp_cls.vue' /* webpackChunkName: "pages/motor/comp_cls" */))
const _7e7956b0 = () => interopDefault(import('../pages/motor/down_files.vue' /* webpackChunkName: "pages/motor/down_files" */))
const _40537bfe = () => interopDefault(import('../pages/motor/first_prm.vue' /* webpackChunkName: "pages/motor/first_prm" */))
const _500693a4 = () => interopDefault(import('../pages/motor/left_table.vue' /* webpackChunkName: "pages/motor/left_table" */))
const _69eb0d2a = () => interopDefault(import('../pages/motor/map_prm.vue' /* webpackChunkName: "pages/motor/map_prm" */))
const _14350414 = () => interopDefault(import('../pages/motor/market_price.vue' /* webpackChunkName: "pages/motor/market_price" */))
const _0921efd9 = () => interopDefault(import('../pages/motor/mom_prm.vue' /* webpackChunkName: "pages/motor/mom_prm" */))
const _0cf1f42a = () => interopDefault(import('../pages/motor/motor_dash.vue' /* webpackChunkName: "pages/motor/motor_dash" */))
const _5ac62ad6 = () => interopDefault(import('../pages/motor/near_miss.vue' /* webpackChunkName: "pages/motor/near_miss" */))
const _0f3e7531 = () => interopDefault(import('../pages/motor/prm_comp.vue' /* webpackChunkName: "pages/motor/prm_comp" */))
const _11bdf50e = () => interopDefault(import('../pages/motor/prm_prod.vue' /* webpackChunkName: "pages/motor/prm_prod" */))
const _bc29876c = () => interopDefault(import('../pages/motor/prod_compar.vue' /* webpackChunkName: "pages/motor/prod_compar" */))
const _af685cd2 = () => interopDefault(import('../pages/motor/prod_time.vue' /* webpackChunkName: "pages/motor/prod_time" */))
const _3c69193d = () => interopDefault(import('../pages/motor/quot_ot.vue' /* webpackChunkName: "pages/motor/quot_ot" */))
const _f4e4a6fa = () => interopDefault(import('../pages/motor/quot_prm.vue' /* webpackChunkName: "pages/motor/quot_prm" */))
const _58c6fffa = () => interopDefault(import('../pages/motor/rnk_ot.vue' /* webpackChunkName: "pages/motor/rnk_ot" */))
const _2b4a1c5a = () => interopDefault(import('../pages/motor/what_if.vue' /* webpackChunkName: "pages/motor/what_if" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _c269bd36,
    name: "login___ie"
  }, {
    path: "/home/comp_cls",
    component: _d34806b4,
    name: "home-comp_cls___ie"
  }, {
    path: "/home/cost_credit",
    component: _0dfd0d2f,
    name: "home-cost_credit___ie"
  }, {
    path: "/home/down_files",
    component: _367a8f74,
    name: "home-down_files___ie"
  }, {
    path: "/home/first_prm",
    component: _11aba6c0,
    name: "home-first_prm___ie"
  }, {
    path: "/home/home_dash",
    component: _f8cfc814,
    name: "home-home_dash___ie"
  }, {
    path: "/home/left_table",
    component: _7405f742,
    name: "home-left_table___ie"
  }, {
    path: "/home/map_prm",
    component: _dacd0368,
    name: "home-map_prm___ie"
  }, {
    path: "/home/market_price",
    component: _35e9f832,
    name: "home-market_price___ie"
  }, {
    path: "/home/mom_prm",
    component: _31d060fb,
    name: "home-mom_prm___ie"
  }, {
    path: "/home/near_miss",
    component: _ed18c992,
    name: "home-near_miss___ie"
  }, {
    path: "/home/prm_comp",
    component: _0743af62,
    name: "home-prm_comp___ie"
  }, {
    path: "/home/prm_prod",
    component: _377e8ed2,
    name: "home-prm_prod___ie"
  }, {
    path: "/home/prod_compar",
    component: _044f6728,
    name: "home-prod_compar___ie"
  }, {
    path: "/home/prod_time",
    component: _5f228239,
    name: "home-prod_time___ie"
  }, {
    path: "/home/quot_ot",
    component: _65178a5f,
    name: "home-quot_ot___ie"
  }, {
    path: "/home/quot_prm",
    component: _72ad5fa1,
    name: "home-quot_prm___ie"
  }, {
    path: "/home/rnk_ot",
    component: _66ab3abe,
    name: "home-rnk_ot___ie"
  }, {
    path: "/home/what_if",
    component: _53f88d7c,
    name: "home-what_if___ie"
  }, {
    path: "/motor/comp_cls",
    component: _ad876cf0,
    name: "motor-comp_cls___ie"
  }, {
    path: "/motor/down_files",
    component: _7e7956b0,
    name: "motor-down_files___ie"
  }, {
    path: "/motor/first_prm",
    component: _40537bfe,
    name: "motor-first_prm___ie"
  }, {
    path: "/motor/left_table",
    component: _500693a4,
    name: "motor-left_table___ie"
  }, {
    path: "/motor/map_prm",
    component: _69eb0d2a,
    name: "motor-map_prm___ie"
  }, {
    path: "/motor/market_price",
    component: _14350414,
    name: "motor-market_price___ie"
  }, {
    path: "/motor/mom_prm",
    component: _0921efd9,
    name: "motor-mom_prm___ie"
  }, {
    path: "/motor/motor_dash",
    component: _0cf1f42a,
    name: "motor-motor_dash___ie"
  }, {
    path: "/motor/near_miss",
    component: _5ac62ad6,
    name: "motor-near_miss___ie"
  }, {
    path: "/motor/prm_comp",
    component: _0f3e7531,
    name: "motor-prm_comp___ie"
  }, {
    path: "/motor/prm_prod",
    component: _11bdf50e,
    name: "motor-prm_prod___ie"
  }, {
    path: "/motor/prod_compar",
    component: _bc29876c,
    name: "motor-prod_compar___ie"
  }, {
    path: "/motor/prod_time",
    component: _af685cd2,
    name: "motor-prod_time___ie"
  }, {
    path: "/motor/quot_ot",
    component: _3c69193d,
    name: "motor-quot_ot___ie"
  }, {
    path: "/motor/quot_prm",
    component: _f4e4a6fa,
    name: "motor-quot_prm___ie"
  }, {
    path: "/motor/rnk_ot",
    component: _58c6fffa,
    name: "motor-rnk_ot___ie"
  }, {
    path: "/motor/what_if",
    component: _2b4a1c5a,
    name: "motor-what_if___ie"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
